import { Cart } from '@msdyn365-commerce/retail-proxy';
import { AttributeTextValueClass } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';

const addAttributeToCart = (attrKey: string, attrValue: string, cart: Cart): Cart => {
    const _cart = { ...cart };
    const attributeArray = _cart.AttributeValues || [];
    const attributeObj = {
        '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
        Name: attrKey,
        ExtensionProperties: [],
        TextValue: attrValue,
        TextValueTranslations: []
    };
    const attrIdx = attributeArray.findIndex(a => a.Name?.toUpperCase() === attrKey.toUpperCase());

    //Update existing attribute if there.
    if (attrIdx > -1) {
        (attributeArray[attrIdx] as AttributeTextValueClass).TextValue = attrValue;
    } else {
        attributeArray.push(attributeObj);
    }

    _cart.AttributeValues = attributeArray;

    return _cart;
};

const removeAttributeFromCart = (attrKey: string, cart: Cart): Cart => {
    const _cart = { ...cart };
    const attributeArray = _cart.AttributeValues || [];
    const attrIdx = attributeArray.findIndex(a => a.Name?.toUpperCase() === attrKey.toUpperCase());

    //We can't remove attribute values for some reason, so we'll just set the value to ''
    if (attrIdx > -1) {
        (attributeArray[attrIdx] as AttributeTextValueClass).TextValue = '';
    }
    _cart.AttributeValues = attributeArray;
    return _cart;
};

export { addAttributeToCart, removeAttributeFromCart };
